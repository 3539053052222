<template>
	<div>
		<v-container class="mt-5">
			<!-- <v-row class="d-flex justify-center center">
				<div class="text-center d-flex section-title">
					<v-col cols="12">
						<h2 class="heading-title">NCL News</h2>
					</v-col>
				</div>
			</v-row>
			<hr /> -->
			<NewsLists />
		</v-container>
	</div>
</template>
<script>
import NewsLists from "../Components/NLSE/News/NewsLists.vue";

export default {
	components: {
		NewsLists,
	},
};
</script>
<style scoped>
/* Import OSWALD Fonts Link */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
p {
	text-align: left;
	font: normal normal bold 30px/45px Oswald;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}

.heading-title {
	text-align: left;
	font: normal normal bold 80px/118px Oswald;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
}

hr {
	border: 1px solid #000000;
	margin-bottom: 4em;
}
</style>
